<template>
  <div class="base">
    <div class="card-title">意见反馈</div>
    <div class="card">
      <div class="card-cell">
        <div class="cell-title">
          <span class="required">*</span>
          <span>反馈标题</span>
        </div>
        <div class="cell-content">{{ dataObj.title }}</div>
      </div>
      <div class="card-cell textarea-cell">
        <div class="cell-title">
          <span class="required">*</span>
          <span>反馈内容</span>
        </div>
        <div class="cell-content">{{ dataObj.content }}</div>
      </div>
    </div>
    <div class="interval"></div>
    <div class="card-title">反馈图片</div>
    <div class="card">
      <div class="card-cell imgs-cell" v-if="fileList.length > 0">
        <img class="up-img" v-for="(item, index) in fileList" :key="index" :src="item" @click="showImg(item)" />
      </div>
      <div class="card-cell imgs-cell" v-if="fileList.length == 0">
        <div class="no-img">未上传现场照片</div>
      </div>
    </div>
    <!--    如果已经完成维修-->
    <template v-if="dataObj.status == '1'">
      <div class="card-title">处理结果</div>
      <div class="card">
        <div class="card-cell textarea-cell">
          <div class="cell-title">
            <span class="required">*</span>
            <span>处理内容</span>
          </div>
          <div class="cell-content">{{ dataObj.handleContent }}</div>
        </div>
        <div class="card-cell">
          <div class="cell-title">
            <span class="required">*</span>
            <span>处理人</span>
          </div>
          <div class="cell-content">{{ dataObj.handleUserId_dictText }}</div>
        </div>
        <div class="card-cell">
          <div class="cell-title">
            <span class="required">*</span>
            <span>处理时间</span>
          </div>
          <div class="cell-content">{{ dataObj.handleTime }}</div>
        </div>
      </div>
      <div class="footer"></div>
    </template>
    <mt-popup v-model="showImgFlag">
      <img :src="tempImgUrl" class="big-img" />
    </mt-popup>
  </div>
</template>

<script>
import UploadImg from '@/components/UploadImg'
import { Indicator } from 'mint-ui'
import { getFeedbackDetail } from '@/api/enterprise'

export default {
  components: { UploadImg },
  data() {
    return {
      id: '',
      submiting: true,
      fileList: [],
      handleImagePath: [],
      dataObj: {},
      showImgFlag: false,
      tempImgUrl: ''
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getDetail()
  },
  methods: {
    getDetail() {
      const that = this
      Indicator.open('请稍等...')
      getFeedbackDetail({ id: that.id })
        .then((res) => {
          that.dataObj = res.result
          if (res.result.image) {
            that.fileList = res.result.image.split(',')
          }
        })
        .catch((err) => {
          that.requestFailed(err)
        })
        .finally(() => {
          Indicator.close()
        })
    },
    showImg(item) {
      this.showImgFlag = true
      this.tempImgUrl = item
    }
  }
}
</script>

<style lang="less" scoped>
.base {
  background: #f7f8fc;
  min-height: 100vh;
  font-weight: 'Source Han Sans CN';

  .card-title {
    font-weight: 400;
    font-size: 15px;
    color: #999999;
    padding: 0px 20px;
    height: 55px;
    line-height: 55px;
  }

  .card {
    background: #ffffff;
    padding: 0px 20px;
    box-shadow: 0px 0px 2px 0px rgba(23, 140, 249, 0.1);

    &-cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f7f8fc;

      .cell-title {
        height: 50px;
        line-height: 50px;
      }

      .required {
        color: red;
      }

      .cell-content {
        width: 220px;
        font-size: 14px;
        line-height: 30px;
        text-align: right;
        display: -webkit-box;
        -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-line-clamp: 2;
      }
    }

    .textarea-cell {
      flex-direction: column;
      align-items: flex-start;

      .cell-content {
        width: 100%;
        min-height: 149px;
        text-align: left;
        -webkit-line-clamp: 3;
        background: rgba(242, 242, 242, 0.8);
        border-radius: 10px;
        padding: 5px 10px;
        line-height: 35px;
      }
    }

    .imgs-cell-title {
      height: 50px;
      line-height: 50px;
      margin-bottom: -20px;

      .required {
        color: red;
      }
    }

    .imgs-cell {
      justify-content: flex-start;

      .up-img {
        margin: 20px 17.5px 20px 0px;
        width: 100px;
        height: 100px;
      }

      .no-img {
        color: #999999;
        line-height: 50px;
        height: 50px;
      }
    }
  }
}

.big-img {
  width: 375px;
}

.interval {
  height: 20px;
  background: #ffffff;
}
</style>
